<template>
  <div>
    <NModal
      v-model:show="modalShow"
      preset="card"
      title="查看"
      style="width: 850px;"
      :segmented="{ content: 'hard' }"
    >
      <template #default>
        <BriefInfo
          :brief-info="briefInfo"
        />
        <LabelInfo
          :label-list="labelList"
        />
        <ExerciseItem
          :exercise-index="-1"
          :exercise-data="exerciseData"
        />
      </template>
      <template #footer>
        <NSpace justify="end">
          <NButton type="primary" @click="modalShow = false">确定</NButton>
        </NSpace>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import BriefInfo from './BriefInfo.vue';
  import LabelInfo from '@/views/teaching/preview/components/LabelInfo.vue';
  import PageLoading from '@/components/PageLoading/index.vue';
  import ExerciseItem from '@/views/material/preview/components/ExerciseItem.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import questionTypeMap from '@/enumerators/question-type-map-all.js';

  import { getExerciseDetail } from '@/api/exercises.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const modalShow = ref(false);
  const briefInfo = ref({});
  const labelList = ref([]);
  const exerciseData = ref({});

  function updateExerciseDetail(exerciseId) {
    loading.value = true;
    getExerciseDetail({
      'Exercises[id]': exerciseId
    }).then(({code, data}) => {
      if (code === SUCCESS) {
        const {
          admin_username,
          update_time,
          type,
          label_list,

          title,
          ques_select,
          ques_answer,
          ques_analys,
          score,
          upload_list
        } = data;
        briefInfo.value = {
          creator: admin_username,
          updateTime: update_time * 1000,
          type: questionTypeMap[type]
        };
        labelList.value = label_list.map(item => item.title);
        exerciseData.value = {
          title,
          options: ques_select,
          answer: ques_answer,
          analysis: data.ques_analys,
          score,
          type,
          fileList: upload_list
        };
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  defineExpose({
    view(exerciseId) {
      modalShow.value = true;
      updateExerciseDetail(exerciseId);
    }
  });
</script>