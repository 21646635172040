<template>
  <div class="exercise-item">
    <div class="exercise-title">
      <div class="title-content">
        <div v-if="exerciseIndex !== -1" class="exercise-seq">{{ exerciseIndex + 1 }}、</div>
        {{ exerciseData.title }}
      </div>
      <!-- 非实训作业时 图片展示 -->
      <template v-if="Array.isArray(exerciseData.fileList) && exerciseData.fileList.length > 0&&Number(exerciseData.type) != 6">
        <NImageGroup>
        <NSpace style="width:450px">
          <NImage v-for="(item,index) in exerciseData.fileList" :key="index" show-toolbar-tooltip
            width="130"
            :src="correctURL('file', item.file_url)"
          />
        </NSpace>
      </NImageGroup>
      </template>
    <!-- 非实训作业时 图片展示结束 -->
      <div class="exercise-score">
        <div>{{ exerciseData.score }}分</div>
        <div>{{ questionTypeMap[exerciseData.type] }}</div>
      </div>
    </div>

    <div
      v-if="Number(exerciseData.type) === 6"
      style="margin-bottom: 15px;"
    >
      <template v-if="Array.isArray(exerciseData.fileList) && exerciseData.fileList.length > 0">
        <div
          v-for="(item, index) in exerciseData.fileList"
          :key="index"
        >
          <NButton
            type="primary"
            text
            @click="handleDownload(correctURL('file', item.file_url), item.file_name)"
          >{{ item.file_name }}</NButton>
        </div>
      </template>
      <template v-else>
        <div class="no-homework-file">（无作业文件）</div>
      </template>
    </div>
    <div
      class="exercise-options"
      v-else-if="Number(exerciseData.type) !== questionTypeEnum.OPEN && Array.isArray(exerciseData.options) && exerciseData.options.length > 0"
    >
      <template v-if="Number(exerciseData.type) === questionTypeEnum.FILL_BLANK">
        <div
          v-for="(item, index) in answers"
          :key="index"
          class="option is-answer"
        >
          <NIcon class="option-check" size="20" :color="themeSettings.successColor">
            <CheckmarkSharp />
          </NIcon>

          <div class="option-content">填空答案{{ index + 1 }}：{{ item }}</div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in exerciseData.options"
          :key="index"
          class="option"
          :class="{ 'is-answer': answers.includes(item[0]) }"
        >
          <NIcon class="option-check" size="20" :color="themeSettings.successColor">
            <CheckmarkSharp />
          </NIcon>
          <div class="option-content">{{ item }}</div>
        </div>
      </template>
    </div>
    <div
      v-else-if="Number(exerciseData.type) === questionTypeEnum.OPEN"
      style="margin: -2px 0 14px; color: #999;"
    >（由考生上传答案）</div>
    <div class="exercise-analysis">
      <div class="analysis-title">习题解析：</div>
      <div class="analysis-content">{{ exerciseData.analysis || '（无）' }}</div>
    </div>

    <PageLoading v-if="Number(exerciseData.type) === 6" :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { CheckmarkSharp } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';

  import themeSettings from '@/settings/theme-settings.js'; 
  import questionTypeMap, { questionTypeEnum } from '@/enumerators/question-type-map.js';

  import { correctURL } from '@/utils/url.js';
  import { saveOSSFile } from '@/utils/utils.js';

  const props = defineProps({
    exerciseIndex: {
      type: Number,
      default: 0
    },
    exerciseData: {
      type: Object,
      default: () => {}
      // { title: '', options: ['A.XXX', 'B.XXX'], answer: 'A B', score: 'xx.xx', type: x, analysis: xx, fileList: [] }
    }
  });

  const answers = computed(() => {
    const answerArr = props.exerciseData.answer;
    return Array.isArray(answerArr) ?
      answerArr :
      [];
  });

  const loading = ref(false);
  function handleDownload(fileURL, fileName) {
    loading.value = true;
    saveOSSFile(fileURL, fileName).finally(() => {
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    });
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .exercise-item {
    padding: 15px 20px;
    border-bottom: 1px solid #ddd;
  }

  .exercise-title,
  .exercise-options {
    margin-bottom: 14px;
  }

  .exercise-title {
    position: relative;
    word-break: break-all;
    min-height: 23px;
  }
  .title-content {
    margin-right: 60px;
    font-weight: bold;
  }
  .exercise-seq {
    float: left;
  }
  .exercise-score {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 17px;
    text-align: right;
    color: @theme-color;
  }

  .option {
    position: relative;
    margin-bottom: 4px;

    &.is-answer {
      background-color: #FDE8CC;

      .option-check {
        display: block;
      }
    }
  }
  
  .option-check {
    position: absolute;
    left: 10px;
    top: 2px;
    display: none;
  }
  .option-content {
    margin-left: 60px;
    word-break: break-all;
  }
  
  .exercise-analysis {
    position: relative;
    padding-left: 80px;
  }
  .analysis-title {
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
  }
  .analysis-content {
    min-height: 22px;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .no-homework-file {
    color: #999;
  }
</style>