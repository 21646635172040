<template>
  <NForm
    inline
    label-placement="left"
  >
    <NFormItem label="搜索标题：">
      <NInput
        maxlength="50"
        placeholder="请输入ID或标题"
        show-count
        clearable
        v-model:value="formValue.title"
      />
    </NFormItem>
    <NFormItem label="题型：" v-if="!currentTask">
      <div style="width: 200px;">
        <NSelect
          :options="typeOptions"
          v-model:value="formValue.type"
        />
      </div>
    </NFormItem>
    <NFormItem>
      <NSpace>
        <NButton type="primary" @click="handleSearch">搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </NSpace>
    </NFormItem>
  </NForm>
</template>

<script setup>
  import { ref, reactive } from 'vue';

  import questionTypeMap from '@/enumerators/question-type-map.js';

  const props = defineProps({
    // 实训作业时 隐藏题型选择
    currentTask: {
      type: Boolean,
      default: false
    }
  });
  
  const typeOptions = (() => Object.keys(questionTypeMap).map(value => ({ label: questionTypeMap[value], value })))();
  const getDefaultFormValue = () => ({
    title: '',
    type: null
  });
  const formValue = reactive(getDefaultFormValue());

  const getSearchParams = () => {
    const params = {};
    Object.keys(formValue).forEach(key => {
      const value = formValue[key];
      value && (params[key] = value);
    });
    return params;
  };

  const emit = defineEmits(['list-update']);
  const handleSearch = () => {
    emit('list-update');
  };
  const handleClear = () => {
    Object.assign(formValue, getDefaultFormValue());
    handleSearch();
  };

  defineExpose({
    getSearchParams
  });
</script>